/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                // Scripts from old site
                (function ($, root, undefined) {

                    $(function () {

                        'use strict';

                        // SVG fix for browsers that don't support them
                        if (!Modernizr.svg) {
                            $('img[src*="svg"]').attr('src', function () {
                                return $(this).attr('src').replace('.svg', '.png');
                            });
                        }

                        // Add class for iOS
                        /*if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
                            $('body').addClass('iOS');
                        }*/

                        // Homepage / Nav scroll features
                        $(window).load(function () { navSetup(); });
                        $(window).scroll(function () { navSetup(); });
                        function navSetup() {
                            if ($(window).scrollTop() >= 100) {
                                $('#nav-wrapper, #search-form').addClass('fixed');
                            } else {
                                $('#nav-wrapper, #search-form').removeClass('fixed');
                            }
                        }
                        // if ($('.home').length > 0) {
                        //     $(window).scroll(function () {
                        //         var ws = $(window).scrollTop();
                        //         $('.home #header-content > *, .home a.down-arrow').css('opacity', 1 - ws / $('.home #header').outerHeight() * 1.8);
                        //     });
                        // }

                        // Animated Anchors
                        $(document).on('click', 'a[href*="#"]:not([href="#"])', function () {
                            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                                var target = $(this.hash);
                                var $this = this;
                                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                                if (target.length) {
                                    $('html,body').animate({
                                        scrollTop: target.offset().top
                                    }, 500, function () {
                                        location.hash = $this.hash;
                                    });
                                    return false;
                                }
                            }
                        });

                        // Search Toggle
                        $('#header #site-navigation ul li.menu-item-search').click(function () {
                            $('#search-form').slideToggle();
                            $('#search-form .search-input').focus();
                            return false;
                        });
                        // Search Submit
                        $('#search-form').submit(function (e) {
                            var s = $(this).find(".search-input");
                            if (!s.val()) {
                                e.preventDefault();
                                shake($(this).find('.search-input'));
                                $(this).find('.search-input').focus();
                            }
                        });

                        // Cleanup empty p tags
                        $(".wrapper p").each(function () {
                            var elem = $(this);
                            if ($.trim(elem.html()).length == 0) {
                                elem.remove();
                            }
                        });

                        // Swap 3/4 + 1/4 columns on mobile
                        $(window).load(function () { columnswap(); });
                        $(window).resize(function () { columnswap(); });
                        function columnswap() {
                            if ($(document).width() < 750) {
                                $('.staff-bio .vc_col-sm-3:parent, .post .vc_col-sm-3:parent').each(function () {
                                    $(this).insertBefore($(this).prev('.vc_col-sm-9'));
                                });
                            } else {
                                $('.staff-bio .vc_col-sm-9:parent, .post .vc_col-sm-9:parent').each(function () {
                                    $(this).insertBefore($(this).prev('.vc_col-sm-3'));
                                });
                            }
                        }

                        // Promos
                        $("#home-bottom-promos .promo, .staff-bio-promo").click(function (e) {
                            location.href = $(this).find("a.permalink").attr("href");
                            e.preventDefault();
                        });

                        // Dynamically Adjust Header Size
                        if ($('.home').length > 0) {
                            //resizeHomeHeader();
                            //$(window).resize(function () { resizeHomeHeader() });
                        } else {
                            positionHeader();
                            $(window).resize(function () { positionHeader() });
                        }

                        // Equal height promo boxes
                        $(window).load(function () { equalheight('.role-promo'); });
                        $(window).resize(function () { equalheight('.role-promo'); });
                        $(window).load(function () { equalheight('#home-top-promos .promo'); });
                        $(window).resize(function () { equalheight('#home-top-promos .promo'); });

                        // Responsive navigation
                        $('#header a#toggle-nav').sidr({
                            name: 'sidrnav',
                            source: '#footer-nav',
                            side: 'right'
                        });
                        $('#sidrnav').click(function () {
                            $.sidr('close', 'sidrnav');
                        });

                        $(document).ready(function () {
                            $('.flexslider').flexslider({
                                animation: "slide",
                                controlsContainer: ".slider-controls",
                                directionNav: true,
                            });

                        });

                        $('.info-modal').magnificPopup({
                            type: 'inline',
                            midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
                        });

                    });

                    function resizeHomeHeader() {
                        if ($(window).width() > 900 && $(window).height() > 615) {
                            var newHeight = $(window).height();
                            $('.home #header').css('height', newHeight + 'px');

                            var headerHeight = 0;
                            if ($(document).width() < 1122) headerHeight = $('#nav-wrapper').height();
                            //console.log('nh: ' + newHeight + ' hh: ' + headerHeight + ' hc: ' + $('#header-content').height());
                            var contentMargin = ((newHeight - headerHeight - $('#header-content').height()) / 2) + 35;
                            if (contentMargin < 0) { contentMargin = 0; }
                            //console.log(contentMargin);
                            $('#header-content').css('top', contentMargin + 'px');
                        } else {
                            if ($(document).width() > 1122) {
                                $('#header-content').css('top', '240px');
                            } else {
                                $('#header-content').css('top', 0);
                                $('.home #header').css('height', 'inherit');
                            }
                        }
                    }

                    function positionHeader() {
                        if ($(document).width() > 1122) {
                            var headerHeight = $('#header').height() - $('#nav-wrapper').height();
                            var contentHeight = $('#header-content').height();
                            var contentMargin = (headerHeight - contentHeight) / 4;
                            if (contentMargin < 0) { contentMargin = 0; }
                            $('#header-content').css('padding-top', contentMargin + $('#nav-wrapper').height() + 'px');
                        } else {
                            $('#header-content').css('padding-top', 0);
                        }
                    }

                    function shake(div) {
                        var interval = 100;
                        var distance = 10;
                        var times = 4;

                        $(div).css('position', 'relative');

                        for (var iter = 0; iter < (times + 1); iter++) {
                            $(div).animate({
                                left: ((iter % 2 == 0 ? distance : distance * -1))
                            }, interval);
                        }

                        $(div).animate({ left: 0 }, interval);
                    }

                    // Equal Heights Function
                    equalheight = function (container) {
                        var currentTallest = 0,
                            currentRowStart = 0,
                            rowDivs = new Array(),
                            $el,
                            topPosition = 0;

                        $(container).each(function () {
                            $el = $(this);
                            $($el).height('auto')
                            topPostion = $el.position().top;

                            if (currentRowStart != topPostion) {
                                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                                    rowDivs[currentDiv].height(currentTallest);
                                }
                                rowDivs.length = 0; // empty the array
                                currentRowStart = topPostion;
                                currentTallest = $el.height();
                                rowDivs.push($el);
                            } else {
                                rowDivs.push($el);
                                currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
                            }
                            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                                rowDivs[currentDiv].height(currentTallest);
                            }
                        });
                    }

                    $(document).ready(function () {
                        $('#input_2_6').change(function (e) {
                            var fileName = '';
                            var $label = $('#input_2_6').parent().prev();

                            if (e.target.value) {
                                fileName = e.target.value.split('\\').pop();
                            }

                            if (fileName) {
                                if (fileName.length > 10) {
                                    fileName = fileName.substring(0, 9) + "...";

                                }
                                $label.html(fileName);
                                console.log(fileName);
                                console.log(fileName.length);
                            }
                        })
                    });


                    $(document).ready(function () {
                        $("li.sf-level-0:nth-of-type(2)").addClass("sf-option-active");
                    });

                    // if ( document.getElementById('sf-input-7e9a2cc305910342aa4f0af5c2cca349').checked === true ) {
                    // 	$('#e-book-info').show();
                    // 	console.log('e-books selected');
                    // } 

                    function eBookInfoToggle() {
                        var $e_book_info = $('#e-book-info');
                        var $input = $('input[value="e-book"]');
                        $input.on('change', function () {
                            $e_book_info.toggle();
                            console.log('toggle switch');

                        });
                    }

                    $(document).ready(function () {
                        var $e_book_info = $('#e-book-info');
                        var $input = $('input[value="e-book"]');
                        if ($input.is(":checked")) {
                            console.log('checked');
                            $e_book_info.show();
                        }
                        eBookInfoToggle();
                    });

                    $(document).on("sf:ajaxfinish", ".searchandfilter", function () {
                        console.log("ajax complete");
                        //so load your lightbox or JS scripts here again
                        eBookInfoToggle();
                    });

                    // // Parse the URL parameter
                    // function getParameterByName(name, url) {
                    //     if (!url) {
                    //     	url = window.location.href;
                    //     }
                    //     name = name.replace(/[\[\]]/g, "\\$&");
                    //     var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                    //         results = regex.exec(url);
                    //     if (!results) {return null;}
                    //     if (!results[2]) {return '';}
                    //     return decodeURIComponent(results[2].replace(/\+/g, " "));
                    // }
                    // // Give the parameter a variable name
                    // var dynamicContent = getParameterByName('_sft_type');

                    // if (dynamicContent == 'e-book') {
                    // 	$('#e-book-info').show();
                    // } else {
                    // 	$('#e-book-info').hide();
                    // }

                })(jQuery, this);

                // Make Wild Apricot Username link to member page

                var $wa_username = $('.wa_login_shortcode p');

                if ($wa_username) {
                    $wa_username.wrap('<a href="/member/"></a>');
                }

                var visceralSlider = $('.visceral-slider');

                if (visceralSlider) {
                    visceralSlider.each(function () {
                        console.log($(this));
                        var slider = $(this).find('> .wpb_column > .vc_column-inner > .wpb_wrapper');
                        var slide = '.visceral-slider > .wpb_column > .vc_column-inner > .wpb_wrapper > *';

                        slider.slick({
                            slide: slide,
                            autoplay: true,
                            fade: false,
                            speed: 1200,
                            infinite: true,
                            cssEase: 'ease',
                            // centerMode: true,
                            // centerPadding: '0px',
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            swipeToSlide: true,
                            dots: true,
                            arrows: true,
                            mobileFirst: true,
                            rows: 0,
                            nextArrow: '<button type="button" class="slick-next"><span class="screen-reader-text">Next</span> <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2 18L10 10L2 2" stroke-width="3" stroke-linecap="round" /> </svg></button>',
                            prevArrow: '<button type="button" class="slick-prev"><span class="screen-reader-text">Previous</span> <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11 2L3 10L11 18" stroke-width="3" stroke-linecap="round" /> </svg></button>',
                        });
                    });
                }

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        'single_resource': {
            init: function () {
                // JavaScript to be fired on single resource pages

                /** COOKIE FUNCTIONS */
                function createCookie(name, value, days) {
                    var expires = "";
                    if (days) {
                        var date = new Date();
                        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                        expires = "; expires=" + date.toUTCString();
                    }
                    document.cookie = name + "=" + value + expires + "; path=/";
                }

                function readCookie(name) {
                    var nameEQ = name + "=";
                    var ca = document.cookie.split(';');
                    for (var i = 0; i < ca.length; i++) {
                        var c = ca[i];
                        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
                    }
                    return null;
                }

                function eraseCookie(name) {
                    createCookie(name, "", -1);
                }
                /** END COOKIE FUNCTIONS */


                var $modalBG = $('.modal-bg');
                var $resourceURL;
                var downloadGate = readCookie('ncc_resource_download_gate');

                $('.resource-download-links a.resource-download').on('click', function (e) {
                    // Check if should NOT have download gate 
                    if (!$(this).hasClass('no-gate')) {

                        var a = new RegExp('/' + window.location.host + '/');

                        if (a.test(this.href)) { // if url is  an internal link 
                            if (downloadGate === null) {
                                e.preventDefault();
                                $resourceURL = $(this).attr('href');
                                $modalBG.show(); // prompt form
                            }
                        }
                    }
                });

                // close modal
                $('.download-modal .icon-cancel').on('click', function () {
                    $modalBG.hide();
                });

                // once form is submitted and validated, set cookie and open resource url
                $(document).bind('gform_confirmation_loaded', function () {
                    createCookie('ncc_resource_download_gate', '', 365);
                    downloadGate = false;
                    window.open($resourceURL, '_blank');
                });

                // what is thisssss?
                $('.resource-download').bind('contextmenu', function (e) {
                    return false;
                });

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
